// src/components/Testimonials.js
import React, { useEffect, useRef, useState } from 'react';
import Testimonial1 from '../../assets/images/testimonial.png';
import Svgs from '../../assets/svgs';

const testimonials = [
  {
    text: "I can’t imagine how fast TA helped me find the best agent nearby and the way they helped me save money on my deal. Amazing!",
    author: "Andy Smith",
    title: "Founder, Apple",
    image: Testimonial1,
  },
  {
    text: "TA made my home search effortless, connecting me with a fantastic agent who saved me so much money. Truly remarkable!",
    author: "John Doe",
    title: "CEO, Example Inc.",
    image: Testimonial1,
  },
];

const TestimonialSection = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [lineAnimate, setLineAnimate] = useState(false);
  const [email, setEmail] = useState('');

  const testimonialRef = useRef(null);

  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);


  const handleStartSaving = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }

    try {
      const apiUrl = `https://api.trustagent.com.au/api/v1/auth/checkEmail?email=${email}`;
      const response = await fetch(apiUrl, { method: 'GET' });

      const responseText = await response.text();
      const data = JSON.parse(responseText);

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }
      setApiResponse(data); // ✅
      if (data) {
        if (data?.data?.user === true) {
          if (data?.data?.role === "User") {
            window.location.href = `https://dashboard.trustagent.com.au/signin?email=${email}`;
          } else {
            window.location.href = `https://dashboard.trustagent.com.au/agent-signin?email=${email}`;
          }
        } else {
          window.location.href = `https://dashboard.trustagent.com.au/?email=${email}`;
        }
      }

    } catch (err) {
      console.error("API Error:", err.message);
    }
  };

  const handleNext = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    setAnimate(true);
  };

  const handlePrev = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    setAnimate(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setLineAnimate(true); // Trigger line animation
        }
      },
      { threshold: 0.1 }
    );

    if (testimonialRef.current) {
      observer.observe(testimonialRef.current);
    }

    return () => {
      if (testimonialRef.current) {
        observer.unobserve(testimonialRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (animate) {
      const timeout = setTimeout(() => setAnimate(false), 600);
      return () => clearTimeout(timeout);
    }
  }, [animate]);

  return (
    <div id="testimonials" className="flex flex-col py-10 px-4 lg:px-[3rem]" ref={testimonialRef}>
      <div
        className={`border-t-8 border-custom-theme w-1/3 lg:w-52 my-4 lg:mx-0 ${lineAnimate ? 'line-animation' : 'w-0'}`} // Add line animation class
      ></div>
      <h2 className="md:text-[56px] text-[42px] font-extrabold uppercase">Testimonials</h2>
      <h3 className='text-custom-heading text-lg font-medium mt-3 ml-2'>What people say about us</h3>

      <div className="flex flex-col md:flex-row justify-center w-full md:mb-16 mb-8 mt-16">
        <div className="w-full md:w-1/2 flex flex-col items-start pr-4">
          <img
            src={testimonials[currentTestimonial].image}
            alt={testimonials[currentTestimonial].author}
            className="w-auto h-[200px] md:h-[400px] object-cover rounded-md border border-gray-300 mb-4 image-zoom"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center items-start md:pl-4 gap-8">
          <div className="text-yellow-500 text-4xl lg:text-6xl">
            <Svgs.QuoteIcon />
          </div>
          <div className={`${animate ? 'animate-fade-slide' : ''}`}>
            <p className="text-[18px] md:text-3xl text-heading-theme font-medium mb-4 md:w-[80%] w-full">
              {testimonials[currentTestimonial].text}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between md:w-[80%] w-full">
            <div className="lg:text-left">
              <p className="text-lg lg:text-xl text-custom-theme font-normal">{testimonials[currentTestimonial].author}</p>
              <p className="text-custom-heading text-sm lg:text-lg font-medium">{testimonials[currentTestimonial].title}</p>
            </div>
            <div className="flex space-x-4 lg:mt-0">
              <button onClick={handlePrev} className="lg:block hidden image-zoom"><Svgs.TreminalLeft /></button>
              <button onClick={handleNext} className="lg:block hidden image-zoom"><Svgs.TerminalRight /></button>
              <button onClick={handlePrev} className="lg:hidden block image-zoom"><Svgs.TreminalLeftMobile /></button>
              <button onClick={handleNext} className="lg:hidden block image-zoom"><Svgs.TerminalRightMobile /></button>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center flex flex-col gap-4 justify-center items-center mb-8">
        <h3 className="font-bold text-[32px] md:text-7xl text-custom-heading mb-4 leading-tight max-w-6xl">
          Get more cash in your pocket when you sell your house.
        </h3>
        <div className="flex w-full justify-center mt-12">
          <div className="max-w-4xl flex w-full">
            <input value={email}
              onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Your email" className="py-3 px-5 border-2 font-semibold text-[24px] text-[#5E5E5E] rounded-l-md w-1/2 outline-none border-custom-theme" />
            <button onClick={handleStartSaving} className="bg-custom-theme text-sm lg:text-[28px] text-custom-heading p-3 rounded-r-md w-1/2 font-bold signup-btn">START SAVING NOW</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
