import React, { useState } from 'react';
import heroImage from '../../assets/images/hero.png';
import { ReactComponent as DropArrowIcon } from '../../assets/svgs/scrollDown.svg';

const HeroSection = () => {
  const [email, setEmail] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);


  const handleStartSaving = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }

    try {
      const apiUrl = `https://api.trustagent.com.au/api/v1/auth/checkEmail?email=${email}`;
      const response = await fetch(apiUrl, { method: 'GET' });

      const responseText = await response.text();
      const data = JSON.parse(responseText);

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }
      setApiResponse(data); // ✅
      if (data) {
        if (data?.data?.user === true) {
          if (data?.data?.role === "User") {
            window.location.href = `https://dashboard.trustagent.com.au/signin?email=${email}`;
          } else {
            window.location.href = `https://dashboard.trustagent.com.au/agent-signin?email=${email}`;
          }
        } else {
          window.location.href = `https://dashboard.trustagent.com.au/?email=${email}`;
        }
      }

    } catch (err) {
      console.error("API Error:", err.message);
    }
  };



  return (
    <div className="flex flex-col xl:flex-row justify-between p-4 lg:px-16 lg:py-16 xl:gap-0 gap-8 bg-white">
      <div className="xl:w-1/2 w-full flex flex-col gap-4 xl:text-left mt-4">
        <div>
          <div className="border-t-8 border-custom-theme w-[200px] mb-[36px] mt-4 lg:mx-0"></div>
          <h1 className="text-[42px] font-extrabold text-custom-heading tracking-widest lg:text-7xl" style={{ lineHeight: '1.2' }}>
            SELL FAST.<br />SAVE MORE.
          </h1>
        </div>
        <div>
          <p className="md:text-[24px] text-[18px] font-normal text-custom-heading">
            Let top agents compete for your listing, <br /> so you get the best deal effortlessly!
          </p>
        </div>
        <div className="flex flex-col lg:justify-start md:w-[63.5%] w-full mt-4">
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="px-3 h-[79px] border-2 font-semibold text-[24px] text-[#5E5E5E] border-custom-theme rounded mb-4 w-full outline-none"
          />
          {error && <p className="text-red-500 -mt-2 mb-2">{error}</p>}
          <button
            onClick={handleStartSaving}
            className="bg-custom-theme text-[24px] font-bold tracking-wider text-custom-heading h-[72px] mt-1 px-6 rounded signup-btn"
          >
            START SAVING NOW
          </button>
        </div>
      </div>
      <div className="relative xl:w-1/2 mt-8 lg:mt-0 justify-end hidden lg:flex">
        <div className="relative w-full rounded-[8px] image-container">
          <img
            src={heroImage}
            alt="Aerial view of houses"
            className="h-[568px] w-full rounded-[8px] object-cover image-zoom"
          />

        </div>
        <a href="#about-us">
          <DropArrowIcon style={{ width: "108.06px", height: "108.06px" }} className="absolute bottom-24 z-50 -right-12 text-custom-theme cursor-pointer image-zoom" />
        </a>
      </div>
    </div>
  );
};

export default HeroSection;
