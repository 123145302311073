// src/components/HowItWorks.js
import React from 'react';
import HowItWork from '../../assets/images/howitwork.png';
import HowItWorkMobile from '../../assets/images/howitworkmobile.png';

const HowItWorks = () => {
  return (
    <div id="how-it-works" className="bg-custom-heading text-white md:pt-16 py-4 lg:px-16 relative overflow-hidden">
      <div className="relative">
        <div className="border-t-8 border-custom-theme w-1/3 lg:w-36 relative my-4 z-50  md:mx-0 mx-5"></div>
        <h2 className="text-6xl font-extrabold mb-4 relative  md:mx-0 mx-4 z-50 md:leading-0 leading-[72px]">HOW IT WORKS</h2>
        <p className='font-sans font-normal text-[18px] text-[#FFFFFF] lg:w-[40%] md:w-[58%] w-full md:mx-0 mx-5 text-break relative z-50'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </p>
        <div className='md:hidden flex justify-center items-center'>
          <img loading='lazy' src={HowItWorkMobile} className='md:hidden relative max-w-[90%] mt-10 mb-3' />
        </div>
        <img loading='lazy' src={HowItWork} className='hidden md:block xl:-ml-[6rem] -ml-12 xl:-mt-[14.2rem] lg:-mt-[10.2rem] -mt-[8.2rem] relative z-10' />
      </div>
    </div>
  );
};


export default HowItWorks;
