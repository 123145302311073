// src/components/AboutUs.js
import React from 'react';
import AboutUSImage1 from '../../assets/images/aboutus1.jpg';
import AboutUSImage2 from '../../assets/images/aboutus2.jpg';
import AboutUSImage3 from '../../assets/images/aboutus3.jpg';

export default function AboutUs() {
    return (
        <div id="about-us" className="grid grid-cols-1 p-4 lg:px-12 mt-12">
            <div className="border-t-8 border-custom-theme w-1/3 lg:w-72 my-4 lg:mx-0"></div>
            <h2 className="md:text-[56px] text-[42px] font-extrabold uppercase">About Us</h2>
            <h3 className='text-custom-heading text-lg md:w-[75%] font-medium md:mt-3'>You'll have everything necessary to find the right agent, enabling you to compare their information side by side before your first conversation - completely free for homeowners.</h3>
            <div className="flex flex-col lg:flex-row items-center h-auto lg:h-[370px] md:mt-16 mt-5">
                <div className="w-full lg:w-1/2 md:p-6 p-[8px] bg-gray-100 flex flex-col items-center justify-center rounded h-full order-2 lg:order-1">
                    <h1 className="font-bold text-[50px] text-custom-heading uppercase mb-4">LIST</h1>
                    <p className="mt-2 text-custom-heading text-xl lg:text-2xl md:w-[350px] font-medium text-center w-full">
                        Customers list their property details and preferences on feedom.
                    </p>
                </div>
                <div className="w-full lg:w-1/2 h-full order-1 lg:order-2 image-container">
                    <img src={AboutUSImage1} alt="Property" className="h-full w-full object-cover image-zoom" />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center h-auto lg:h-[370px] mt-8 lg:mt-0">
                <div className="w-full lg:w-1/2 h-full image-container">
                    <img src={AboutUSImage2} alt="Property" className="h-full w-full object-cover image-zoom" />
                </div>
                <div className="w-full lg:w-1/2 md:p-6 p-[8px] bg-gray-100 flex flex-col items-center justify-center rounded h-full">
                    <h1 className="font-bold text-[50px] text-custom-heading uppercase mb-4">Compare</h1>
                    <p className="mt-2 text-custom-heading text-xl lg:text-2xl md:w-[350px] font-medium text-center w-full">
                        They compare different fee-saving options and services available.
                    </p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center h-auto lg:h-[370px] mt-8 lg:mt-0">
                <div className="w-full lg:w-1/2 h-full order-1 lg:order-2 image-container">
                    <img src={AboutUSImage3} alt="Property" className="h-full w-full object-cover image-zoom" />
                </div>
                <div className="w-full lg:w-1/2 md:p-6 p-[8px] bg-gray-100 flex flex-col items-center justify-center rounded h-full order-2 lg:order-1">
                    <h1 className="font-bold text-[50px] text-custom-heading uppercase mb-4">Save</h1>
                    <p className="mt-2 text-custom-heading text-xl md:w-[400px] lg:text-2xl font-medium text-center w-full">
                        Finally, they choose the best option and start saving on fees.
                    </p>
                </div>
            </div>
        </div>
    )
}
