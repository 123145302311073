// src/components/Header.js
import React, { useState } from 'react';
import Svgs from '../../assets/svgs';
import Logo from '../../assets/svgs/Pruta.svg';


const Header = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <header className="flex justify-between items-center px-4 py-6 lg:px-16 lg:border-b lg:border-custom-theme">
            <div className="lg:hidden flex items-center">
                <button onClick={toggleSidebar} className="text-custom-heading focus:outline-none">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            <div className=" mx-auto lg:ml-4">
                <img src={Logo} alt="Pruta" className="" />
            </div>
            <nav className="hidden lg:flex items-center space-x-8 mr-4">
                <a href="#how-it-works" className="text-custom-heading font-medium uppercase text-[16px] hover:text-[#FFBF00]">How it Works</a>
                <a href="#about-us" className="text-custom-heading font-medium uppercase text-[16px] hover:text-[#FFBF00]">About Us</a>
                <a onClick={() => localStorage.removeItem('token')} href='https://dashboard.trustagent.com.au/signin' className="text-custom-heading font-[700] uppercase text-[18px] hover:text-[#FFBF00]">Login</a>
                <button
                    className=" bg-custom-theme hover:bg-[#d69d00] text-[18px] font-[700] uppercase w-[162px] h-[50px] text-custom-heading rounded signup-btn"
                    onClick={() => {
                        localStorage.removeItem('token');
                        window.location.href = 'https://dashboard.trustagent.com.au/';
                    }}
                >
                    Sign up & Save
                </button>
            </nav>
            <div className={`fixed inset-0 z-[5000] bg-black bg-opacity-50 transition-opacity duration-300 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleSidebar}>
                <div className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transition-transform duration-300 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`} onClick={(e) => e.stopPropagation()}>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 mt-4">
                        <div className="text-2xl text-custom-heading font-extrabold">Trust Agent</div>

                    </div>
                    <div className='flex flex-col justify-between h-full'>
                        <nav className="flex flex-col py-4 space-y-4">
                            <a onClick={toggleSidebar} href="#how-it-works" className="flex justify-between items-center text-custom-heading font-medium uppercase  text-base	 hover:text-[#FFBF00] border-b border-gray-200 pb-2 px-4">
                                How it Works
                                <Svgs.SidebarArrow />
                            </a>
                            <a onClick={toggleSidebar} href="#about-us" className="flex uppercase justify-between items-center text-custom-heading font-medium  text-base	 hover:text-[#FFBF00]  pb-2 px-4">
                                About Us
                                <Svgs.SidebarArrow />
                            </a>
                        </nav>
                        <div className="mt-auto mb-28 px-4">
                            <a onClick={() => localStorage.removeItem('token')} href='https://dashboard.trustagent.com.au/signin' className="block text-custom-heading font-bold	text-lg hover:text-[#FFBF00] uppercase text-center mb-4">Login</a>
                            <button onClick={() => {
                                localStorage.removeItem('token');
                                window.location.href = 'https://dashboard.trustagent.com.au/';
                            }} className="block px-4 py-2 bg-custom-theme text-custom-heading uppercase font-bold w-full h-[50px] text-lg rounded text-center">Sign up & Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    );
};

export default Header;
